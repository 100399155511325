import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    //component: Home
    component: () => import(/* webpackChunkName: "about" */ '../views/HeatList.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/heatlist',
    name: 'HeatList',
    component: () => import(/* webpackChunkName: "about" */ '../views/HeatList.vue')
  },
  {
    path: '/heatlist_saved',
    name: 'HeatListSaved2',
    component: () => import(/* webpackChunkName: "about" */ '../views/HeatListSaved2.vue')
  },

  {
    path: '/delete_saved_heats',
    name: 'delete_saved_heats',
    beforeEnter: () => { 
      if (typeof(window.localStorage) !== "undefined") {
        window.localStorage.setItem("savedheats","[]");
        if (window.localStorage.savedheats) {
            window.localStorage.setItem("savedheats","[]");
        }
    }
    window.location.href = "/heatlist_saved";
 
    }
  },

  {
    path: '/import',
    name: 'import',
    beforeEnter: () => {

      if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        window.location.href = 'http://podium.localhost/podium/import/uploadform';
      }
      else {
        window.location.href = 'https://testarena2.website/import/uploadform';
      }
    }
  }


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
