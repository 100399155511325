import { createApp } from 'vue'
import axios from 'axios';
import _ from 'lodash';

import mitt from 'mitt';
import App from './App.vue';
import router from './router';
//import store from './store';

//init Materialize framework
M.AutoInit();
window.emitter = window.emitter || new mitt();
/*
axios.defaults.withCredentials = true;
axios.defaults.crossDomain = true;*/

//App.config.globalProperties.emitter = emitter;
createApp(App)
//.use(store)
.use(router)
.mount('#app');